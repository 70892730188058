/* eslint-disable max-len */
import { ReactElement } from 'react';
import * as Pages from '../../../components/pages';
import { IProps } from '../all';
import wallpaper from '../../../assets/images/schulungen_bg.png';

function Page(props: IProps): ReactElement {
    return (
        <Pages.Master show={props.show} selected={props.selected} persistent={
            <div className='top'>
                <Pages.Title>
                    Ausbildung und Schulungen
                </Pages.Title>
            </div>
        } background={<Pages.Wallpaper src={wallpaper} />}>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        5 Schulungsräume mit bis zu 80 PC Arbeitsplätzen.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Monats- und Wochenlehrgänge für die Teilnehmer.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Ausbildung in den Schulungsräumen der Infokom oder direkt beim Kunden.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Zielgruppen sehr unterschiedlich: Kinder & Jugendlichen, Arbeitslose, Umschulende, Facharbeiter, Soldaten und Ingenieure.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='center'>
                    <Pages.PlayButton src='6n7kTGl314Q' title='Die ersten Schulungen' isYoutube />
                </div>
                <div className='bottom'>
                    <Pages.Text>
                        Video: Die ersten Schulungen.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='center'>
                    <Pages.PlayButton src='ELrx39N3OqI' title='Das Schulungszentrum' isYoutube />
                </div>
                <div className='bottom'>
                    <Pages.Text>
                        Video: Das Schulungszentrum.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='center'>
                    <Pages.PlayButton src='j9F91za1dHA' title='Schulungsrichtungen' isYoutube />
                </div>
                <div className='bottom'>
                    <Pages.Text>
                        Video: Schulungsrichtungen.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='center'>
                    <Pages.PlayButton src='Gs_zZK6Qn6M' title='Unterricht mit Behinderten' isYoutube />
                </div>
                <div className='bottom'>
                    <Pages.Text>
                        Video: Unterricht mit Behinderten.
                    </Pages.Text>
                </div>
            </Pages.Child>
        </Pages.Master>
    );
}

const p1995 = [Page, '1995'];

export default p1995;
