export const InfokomLogo = (() => {

  const idPrefix = 'infokom-logo-';
  const svgNameSpace = 'http://www.w3.org/2000/svg';
  const initSize = 400;

  function makeContentCenter(element) {
    element.style.display = 'flex';
    element.style.justifyContent = 'center';
    element.style.alignItems = 'center';

    return element;
  }

  /**
   * 
   * @param {ElementCSSInlineStyle} element 
   * @param {string} size 
   * @returns {ElementCSSInlineStyle}
   */
  function setSize(element, size) {
    element.style.height = size;
    element.style.width = size;

    return element;
  }

  /**
   * 
   * @param {number} min 
   * @param {number} max 
   * @returns {number}
   */
  function randomValue(min, max) {
    return Math.random() * (max - min) + min;
  }

  const blue = '#003070';
  const lightBlue = '#ccd8e4';
  const totalSizeVar = '--total-size';

  class InfokomCircle {

    domElement = document.createElementNS(svgNameSpace, 'g');
    earth = new InfokomEarth();
    stripContainer = new InfokomStripContainer();

    /**
     * @param {string} maskId 
     */
    constructor(maskId) {
      this.domElement.id = `${idPrefix}circle`;
      this.domElement.setAttribute('mask', `url(#${maskId})`);

      const background = document.createElementNS(svgNameSpace, 'rect');
      background.setAttribute('width', initSize.toString());
      background.setAttribute('height', initSize.toString());
      background.setAttribute('fill', blue);

      this.domElement.appendChild(background);
      this.domElement.appendChild(this.earth.domElement);
      this.domElement.appendChild(this.stripContainer.domElement);
    }

    /**
     * 
     * @param {number} size 
     */
    setSize(size) {
      this.domElement.setAttribute('width', size.toString());
      this.domElement.setAttribute('height', size.toString());
    }

  }

  class InfokomEarth {

    domElement = document.createElementNS(svgNameSpace, 'g');
    $size = 75;

    constructor() {
      this.domElement.id = `${idPrefix}earth-container`;
      this.domElement.style.transitionProperty = 'transform';
      this.domElement.style.transformOrigin = 'center';
      this.domElement.setAttribute('width', this.$size.toString());
      this.domElement.setAttribute('height', this.$size.toString());

      this.domElement.appendChild(this.$buildBackground());
      this.domElement.appendChild(this.$buildLandmass());
    }

    $buildBackground() {
      const radius = Math.floor(this.$size / 2);
      const background = document.createElementNS(svgNameSpace, 'circle');
      background.id = `${idPrefix}earth-wrapper`;
      background.setAttribute('width', this.$size.toString());
      background.setAttribute('height', this.$size.toString());
      background.setAttribute('cx', Math.floor(initSize / 2).toString());
      background.setAttribute('cy', (20 + radius).toString());
      background.setAttribute('r', radius.toString());
      background.setAttribute('fill', lightBlue);

      return background;
    }

    $buildLandmass() {
      const groupElement = document.createElementNS(svgNameSpace, 'g');

      const pathPointStrings = [
        'M195 29V31L204 30L216 35L227 38L233 52L237 51C236 38 230 30 216 23H212L205 24L206 26L204 27L199 24L198 25L199 28L195 29Z',
        'M204 34L216 38L222 40L225 41L229 52L221 65L216 78L210 84L208 82L206 78L204 65H206L204 54L191 52L182 48V44L183 41L188 36L204 34Z',
        'M227 65L226 69L221 74L227 65Z',
        'M178 59L165 55C165 60 166 64 169 68L179 61L178 59Z',
      ];

      pathPointStrings.forEach((pointString) => {
        const path = document.createElementNS(svgNameSpace, 'path');
        path.setAttribute('d', pointString);
        path.style.fill = '#45709a';
        path.style.stroke = blue;
        path.style.strokeWidth = '1.5';

        groupElement.appendChild(path);
      });

      return groupElement;
    }

    spin(transitionTime = 500) {
      this.domElement.style.transitionDuration = `${transitionTime}ms`;
      this.domElement.style.transform = `rotate3d(0, 1, 0, 0deg)`;
      this.domElement.style.transform = `rotate3d(0, 1, 0, 360deg)`;
      setTimeout(() => {
        this.domElement.style.transform = `rotate3d(0, 1, 0, 0deg)`;
      }, transitionTime);
    }

  }

  class InfokomRegisterTrademark {

    domElement;

    constructor() {
      this.domElement = document.createElementNS(svgNameSpace, 'path');
      this.domElement.id = `${idPrefix}register-trademark-container`;
      const path = 'M347.531 29.2188C347.531 25.0104 348.5 21.1042 350.438 17.5C352.396 13.8958 355.094 11.0625 358.531 9C361.99 6.91667 365.719 5.875 369.719 5.875C373.719 5.875 377.438 6.91667 380.875 9C384.333 11.0625 387.031 13.8958 388.969 17.5C390.927 21.1042 391.906 25.0104 391.906 29.2188C391.906 33.4688 390.917 37.4062 388.938 41.0312C386.958 44.6354 384.25 47.4688 380.812 49.5312C377.396 51.5938 373.698 52.625 369.719 52.625C365.698 52.625 361.969 51.5833 358.531 49.5C355.115 47.4167 352.427 44.5729 350.469 40.9688C348.51 37.3646 347.531 33.4479 347.531 29.2188ZM351.344 29.2188C351.344 32.6562 352.115 35.8646 353.656 38.8438C355.219 41.8229 357.427 44.2188 360.281 46.0312C363.135 47.8229 366.281 48.7188 369.719 48.7188C373.135 48.7188 376.26 47.8229 379.094 46.0312C381.948 44.2396 384.156 41.8542 385.719 38.875C387.281 35.8958 388.062 32.6771 388.062 29.2188C388.062 25.7604 387.281 22.5521 385.719 19.5938C384.156 16.6146 381.948 14.2396 379.094 12.4688C376.24 10.6771 373.115 9.78125 369.719 9.78125C366.406 9.78125 363.323 10.6354 360.469 12.3438C357.635 14.0521 355.406 16.4062 353.781 19.4062C352.156 22.4062 351.344 25.6771 351.344 29.2188ZM365.219 31.5938V42.125H360.5V15.5625H369.281C372.49 15.5625 374.979 16.2917 376.75 17.75C378.542 19.2083 379.438 21.2292 379.438 23.8125C379.438 26.1042 378.323 27.875 376.094 29.125C377.302 29.7917 378.146 30.7083 378.625 31.875C379.104 33.0417 379.344 34.4896 379.344 36.2188C379.344 37.9271 379.375 39.1354 379.438 39.8438C379.521 40.5521 379.667 41.1458 379.875 41.625V42.125H375.031C374.76 41.4167 374.625 39.3958 374.625 36.0625C374.625 34.4792 374.281 33.3438 373.594 32.6562C372.906 31.9479 371.76 31.5938 370.156 31.5938H365.219ZM365.219 27.4062H369.219C370.802 27.4062 372.115 27.0833 373.156 26.4375C374.219 25.7708 374.75 24.8854 374.75 23.7812C374.75 22.3229 374.375 21.2917 373.625 20.6875C372.896 20.0625 371.562 19.7396 369.625 19.7188H365.219V27.4062Z';
      this.domElement.setAttribute('d', path);
      this.domElement.setAttribute('fill', blue);
    }

  }

  class InfokomStrip {

    domElement = document.createElementNS(svgNameSpace, 'rect');

    $isAnimating = false;
    $initialWidth;
    $initialX;

    /**
     * 
     * @param {{ x: number, y: number, width: number, height: number }} param0 
     */
    constructor({ x, y, width, height }) {
      this.$initialX = x;
      this.$initialWidth = width;

      this.domElement.setAttribute('width', width.toString());
      this.domElement.setAttribute('height', height.toString());
      this.domElement.setAttribute('fill', lightBlue);
      this.domElement.setAttribute('x', x.toString());
      this.domElement.setAttribute('y', y.toString());
    }

    /**
     * 
     * @param {{ growth: number; transitionMin: number; transitionMax: number; }} param0 
     */
    startAnimating({ growth, transitionMin, transitionMax }) {
      this.$isAnimating = true;
      this.$equalizeWidth({ growth, transitionMin, transitionMax });
    }

    $equalizeWidth({ growth, transitionMin, transitionMax }) {
      if (!this.$isAnimating) {
        return;
      }

      const transitionTime = randomValue(transitionMin, transitionMax);
      this.domElement.style.transitionDuration = `${transitionTime}ms`;

      const growthVal = randomValue(-growth, growth);

      const newWidth = this.$initialWidth + growthVal;
      this.domElement.setAttribute('width', newWidth.toString());

      if (this.$isRight()) {
        const newX = this.$initialX - growthVal;
        this.domElement.setAttribute('x', newX.toString());
      }

      setTimeout(() => {
        this.$equalizeWidth({ growth, transitionMin, transitionMax });
      }, randomValue(transitionTime / 2, transitionTime));

    }

    stopAnimating() {
      this.$isAnimating = false;
      this.domElement.setAttribute('width', this.$initialWidth.toString());
      this.domElement.setAttribute('x', this.$initialX.toString());
    }

    $isRight() {
      return this.$initialX > 0;
    }

  }

  class InfokomStripContainer {

    domElement;
    transitionTime = 1000;

    /**
     * @type {InfokomStrip[]}
     */
    $strips = [];

    constructor() {
      this.domElement = document.createElementNS(svgNameSpace, 'g');
      this.domElement.id = `${idPrefix}strip-container`;

      const elementWidth = 240;
      const elementHeight = 10;
      const xOffset = 160;
      const yOffset = elementHeight + 8;
      const yStart = 125;

      for (let i = 0; i <= 13; i++) {
        const elementX = i % 2 === 0 ? xOffset : 0;
        const elementY = yStart + (yOffset * i);

        const strip = new InfokomStrip({
          height: elementHeight,
          width: elementWidth,
          y: elementY,
          x: elementX,
        });
        this.domElement.appendChild(strip.domElement);
        this.$strips.push(strip);
      }
    }

    /**
     * @param {number} growth 
     */
    startEqualize(growth) {
      this.$strips.forEach((strip) => strip.startAnimating({
        growth,
        transitionMin: 800,
        transitionMax: 1200,
      }));
    }

    stopEqualize() {
      this.$strips.forEach((strip) => strip.stopAnimating());
    }

  }

  return class InfokomLogo {

    get dimension() {
      const pixelString = this.domElement.style.getPropertyValue(totalSizeVar);
      const intString = pixelString.substring(0, pixelString.length - 'px'.length);
      const int = parseInt(intString);

      return int;
    }

    /**
     * @param {number} size the absolute container size in px
     */
    set dimension(size) {
      if (size < 0) {
        size = 0;
      }

      this.domElement.style.setProperty(totalSizeVar, `${size}px`);
    }

    get showBackground() {
      const stringValue = this.$background.style.opacity;
      const number = parseFloat(stringValue);

      return number > 0;
    }

    /**
     * @param {boolean} bool if true shows background, otherwise hides it
     */
    set showBackground(bool) {
      this.$background.style.opacity = bool ? '1' : '0';
    }

    $isAnimatable = true;

    get isAnimatable() {
      return this.$isAnimatable;
    }

    /**
     * @param {boolean} bool
     * whether or not the logo animates on specific events
     */
    set isAnimatable(bool) {
      this.$isAnimatable = bool;
      if (!bool) {
        this.$stopAnimating();
      }
    }

    domElement = this.$buildContainer();
    $background = this.$buildBackground();
    $svg = this.$buildSvg();
    $circleMask = this.$buildCircleMask();
    $circle = new InfokomCircle(this.$circleMask.id);
    $registerTrademark = new InfokomRegisterTrademark();

    constructor({
      parentContainer,
      dimension = 400,
      animate = true,
      showBackground = true,
    }) {
      this.dimension = dimension;
      this.showBackground = showBackground;
      this.isAnimatable = animate;

      this.$initElement();
      this.$initAnimations();

      if (parentContainer instanceof HTMLElement) {
        parentContainer.appendChild(this.domElement);
      }

    }

    $initElement() {
      this.domElement.appendChild(this.$background);

      // important to append mask first
      this.$svg.appendChild(this.$circleMask);
      this.$svg.appendChild(this.$circle.domElement);
      this.$svg.appendChild(this.$registerTrademark.domElement);
      this.domElement.appendChild(this.$svg);
    }

    $buildContainer() {
      const paddingVar = '--padding';
      const container = document.createElement('div');
      container.id = `${idPrefix}container`;
      container.style.setProperty(paddingVar, '16px');
      setSize(container, `var(${totalSizeVar})`);
      makeContentCenter(container);
      container.style.padding = '16px';
      container.style.position = 'relative';
      container.style.transitionProperty = 'all';
      container.style.transitionDuration = '100ms';

      return container;
    }

    $buildBackground(backgroundColor = lightBlue) {
      const container = document.createElement('div');
      container.id = `${idPrefix}background`;
      container.style.position = 'absolute';
      setSize(container, '100%');
      container.style.backgroundColor = backgroundColor;
      container.style.opacity = '1';
      container.style.transition = '1000ms';
      container.style.zIndex = '-1';

      return container;
    }

    $buildSvg() {
      const svg = document.createElementNS(svgNameSpace, 'svg');
      svg.id = `${idPrefix}svg`;
      svg.setAttribute('viewBox', `0 0 ${initSize.toString()} ${initSize.toString()}`);
      setSize(svg, '100%');

      return svg;
    }

    $buildCircleMask() {
      const mask = document.createElementNS(svgNameSpace, 'mask');
      mask.id = `${idPrefix}circle-mask`
      const circle = document.createElementNS(svgNameSpace, 'circle');
      const size = Math.floor(initSize / 2).toString()
      circle.setAttribute('cx', size);
      circle.setAttribute('cy', size);
      circle.setAttribute('r', size);
      circle.setAttribute('fill', '#ffffff');

      mask.appendChild(circle);

      return mask;
    }

    $initAnimations() {
      const hoverElement = this.$circle.domElement;

      hoverElement.addEventListener('mouseenter', () => {
        this.$startAnimating()
      });

      hoverElement.addEventListener('mouseleave', () => {
        this.$stopAnimating();
      });
    }

    $startAnimating() {
      if (!this.isAnimatable) {
        return;
      }

      this.$circle.stripContainer.startEqualize(Math.floor(this.dimension / 10));
      this.$circle.earth.spin();
    }

    $stopAnimating() {
      this.$circle.stripContainer.stopEqualize();

      if (!this.isAnimatable) {
        return;
      }

      this.$circle.earth.spin();
    }

  }

})();
