/* eslint-disable max-len */
import { ReactElement } from 'react';
import * as Pages from '../../../components/pages';
import { IProps } from '../all';
import w0 from '../../../assets/images/sms_slide0.jpg';
import w1 from '../../../assets/images/sms_slide1.jpg';
import w2 from '../../../assets/images/sms_slide2.jpg';
import w3 from '../../../assets/images/sms_slide3.jpg';
import w4 from '../../../assets/images/sms_slide4.jpg';
import w5 from '../../../assets/images/sms_slide5.jpg';
import w6 from '../../../assets/images/sms_slide6.jpg';
import w7 from '../../../assets/images/sms_slide7.jpg';
import w8 from '../../../assets/images/sms_slide8.jpg';
import w9 from '../../../assets/images/sms_slide9.jpg';
import w10 from '../../../assets/images/sms_slide10.jpg';
import w11 from '../../../assets/images/sms_slide11.jpg';

function Page(props: IProps): ReactElement {
    return (
        <Pages.Master show={props.show} selected={props.selected} persistent={
            <div className='top'>
                <Pages.Title>
                    SMS
                </Pages.Title>
                <Pages.Subtitle>
                    <u>S</u>tructure <u>M</u>onitoring <u>S</u>ystem
                </Pages.Subtitle>
            </div>
        } background={<Pages.GridWallpaper src={[w0, w1, w2, w3, w4, w5, w6, w7, w8, w9, w10, w11]} />}>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Mehrkomponentenanalyse der statischen und dynamischen Reaktionen des Objektes auf Nutzer- und Umwelteinwirkungen.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Kostengünstige und wartungsarme Fernüberwachung von Tragwerken.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Für den Einsatz zur Langzeit- und Dauerüberwachung von Bauwerken entwickelt.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Breite Auswahlpalette an einsetzbaren Sensoren.
                    </Pages.Text>
                </div>
            </Pages.Child>
        </Pages.Master>
    );
}

const p2001 = [Page, '2001'];

export default p2001;
