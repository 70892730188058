/* eslint-disable max-len */
import { ReactElement } from 'react';
import * as Pages from '../../../components/pages';
import { IProps } from '../all';
import wallpaper from '../../../assets/images/disco_bg.jpg';
import discoLogo from '../../../assets/images/disco_logo.gif';
import supporter from '../../../assets/images/BMWi.svg';

function Page(props: IProps): ReactElement {
    return (
        <Pages.Master show={props.show} selected={props.selected} persistent={
            <div className='top'>
                <Pages.Title>
                    <img src={discoLogo} alt='' />
                </Pages.Title>
                <Pages.Subtitle>
                    InnoRegio Vorhaben DISCO
                </Pages.Subtitle>
                <Pages.Supporter src={supporter} />
            </div>
        } background={<Pages.Wallpaper src={wallpaper} />}>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Entwicklung einer Telematikplattform zum Austausch von medizinischen Daten.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Neue Wege in der Patientenbetreuung.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Aufbau eines medizinischen Kompetenz- und Expertennetzwerks.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Austausch von medizischen Daten wie z.B. Befund, Arztbrief oder Labordaten.
                    </Pages.Text>
                </div>
            </Pages.Child>
        </Pages.Master>
    );
}

const p2006 = [Page, '2006'];

export default p2006;
