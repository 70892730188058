/* eslint-disable max-len */
import { ReactElement } from 'react';
import * as Pages from '../../../components/pages';
import { IProps } from '../all';
import wallpaper from '../../../assets/images/AutoFlow_bg.jpg';
import supporter from '../../../assets/images/EC.png';

function Page(props: IProps): ReactElement {
    return (
        <Pages.Master show={props.show} selected={props.selected} persistent={
            <div className='top'>
                <Pages.Title>
                    AutoFLOW
                </Pages.Title>
                <Pages.Supporter src={supporter} />
            </div>
        } background={<Pages.Wallpaper src={wallpaper} />}>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Akute lymphatische Leukämie bei Kindern und Jugendlichen.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Durchflusszytometrie (FCM) ist ein geeignetes Verfahren zur Erkennung von Blutkrebs.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Objektive und automatische Analysetools für FCM Daten.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Ziel ist die Zeitersparnis auf Seiten des Labors zur Auswertung der Blutproben.
                    </Pages.Text>
                </div>
            </Pages.Child>
        </Pages.Master>
    );
}

const p2019 = [Page, '2019'];

export default p2019;
