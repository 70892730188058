/* eslint-disable max-len */
import { ReactElement } from 'react';
import * as Pages from '../../../components/pages';
import { IProps } from '../all';
import styles from './content.module.scss';
import wallpaper from '../../../assets/images/ausbildungen_bg.jpg';
import ihkImg from '../../../assets/images/ihk.jpg';

function Page(props: IProps): ReactElement {
    return (
        <Pages.Master show={props.show} selected={props.selected} persistent={
            <div className='top'>
                <Pages.Title>
                    Berufsausbildung
                </Pages.Title>
            </div>
        } background={<Pages.Wallpaper src={wallpaper} />}>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Es wurden insgesammt 38 Azubis von Infokom ausgebildet, 3 davon waren Mädchen.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='top'>
                    <Pages.Title />
                    <Pages.Subtitle>
                        Ausbildungsberufe
                    </Pages.Subtitle>
                </div>
                <div className='bottom'>
                    <Pages.Text>
                        Datenverarbeitungskaufmann/-frau, Informatikkaufmann/-frau, IT-Systemkaufmann/-frau, Fachinformatiker/-in Anwendungsentwicklung und Systemintegration.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='center'>
                    <div className={styles.center}>
                        <img src={ihkImg} alt='' />
                    </div>
                </div>
                <div className='bottom'>
                    <Pages.Text>
                        Offiziell anerkannter Ausbildungsbetrieb durch die IHK.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        2013 war Jens Schünemann, Auszubildender bei Infokom, Jahrgangsbester der IHK Neubrandenburg
                    </Pages.Text>
                </div>
            </Pages.Child>
        </Pages.Master>
    );
}

const p2009 = [Page, '2009'];

export default p2009;
