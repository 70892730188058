/* eslint-disable max-len */
import React, { ReactElement } from 'react';
import * as Pages from '../../../components/pages';
import { IProps } from '../all';
import wallpaper from '../../../assets/images/doctor_bg.jpg';
import kbv_logo from '../../../assets/images/kbv_logo.png';
import el_logo from '../../../assets/images/el_logo.png';
import profim_logo from '../../../assets/images/profimed_logo.png';
import styles from './content.module.scss';

class MediumImages extends React.Component<{ src: { img: string, url?: string, title?: string, styles?: React.CSSProperties }[] }> {
    render(): ReactElement {
        const images = this.props.src.map((value) => (
            <div style={value.styles}>
                <div>
                    <img src={value.img} alt='' style={{ cursor: 'pointer' }} onClick={() => {
                        if (value.url) {
                            window.open(value.url, '_blank')?.focus();
                        }
                    }} />
                </div>
                {value.title !== undefined
                    && <span>{value.title}</span>
                }
            </div>
        ));

        return (
            <div className={styles.productImg}>
                {images}
            </div>
        );
    }
}

function Page(props: IProps): ReactElement {
    return (
        <Pages.Master show={props.show} selected={props.selected} persistent={
            <div className='top'>
                <Pages.Title>
                    IT-Service für das<br />Gesundheitswesen
                </Pages.Title>
            </div>
        } background={<Pages.Wallpaper src={wallpaper} />}>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Betreuung von Arztpraxen, Medizinischen Versorgungszentren und Krankenhäusern.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='center'>
                    <MediumImages src={[
                        { img: profim_logo, title: 'Profimed', url: 'https://www.pro-medisoft.de/' },
                        { img: el_logo, title: 'Elaphe Longissima', url: 'https://www.softland.de/' },
                    ]} />
                </div>
                <div className='bottom'>
                    <Pages.Text direction='center'>
                        Einrichtung und Support von Praxisverwaltungssystemen, Netzwerkinfrastruktur, Medizinische Software und Hardware sowie Integration in die <a href='https://gematik.de' target='_blank'>Telematikinfrastruktur</a> (TI).
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Überwachung von Netzwerken und Backups sowie regelmäßige Wartungsarbeiten.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='center'>
                    <MediumImages src={[
                        {
                            img: kbv_logo,
                            url: 'https://www.kbv.de/html/',
                            styles: {
                                maxWidth: '20rem',
                            },
                        },
                    ]} />
                </div>
                <div className='bottom'>
                    <Pages.Text direction='right'>
                        Individuelle Beratung und Betreuung, <a href='https://kbv.de' target='_blank'>IT-Security-Checks</a> und IT-Service.
                    </Pages.Text>
                </div>
            </Pages.Child>
        </Pages.Master>
    );
}

const p2021 = [Page, '2021'];

export default p2021;
