import React, { ReactElement, ReactNode } from 'react';
import { Icon } from 'react-onsenui';
import { pushVideoPlayer } from '../../views/timeline';
import styles from './page.module.scss';

interface IMasterPage {
    show: boolean
    selected: number
    persistent?: ReactElement
    background?: ReactElement
}
class Master extends React.Component<IMasterPage> {
    private $node: React.RefObject<HTMLDivElement>;
    private $childs: HTMLElement[] = [];

    constructor(props: IMasterPage) {
        super(props);

        this.$node = React.createRef();
    }

    updateChilds(current: HTMLDivElement): void {
        for (const child of (Array.prototype.slice.call(current.getElementsByClassName(styles.childShow)) as HTMLElement[])) {
            if (!child.parentElement?.classList.contains(styles.persistent)) {
                child.classList.remove(styles.childShow);
            }
        }

        this.$childs[this.props.selected].classList.add(styles.childShow);
    }

    componentDidUpdate(): void {
        if (this.$node.current !== null) {
            if (this.props.show) {
                this.$node.current.classList.add(styles.masterShow);
                this.updateChilds(this.$node.current);
            } else {
                this.$node.current.classList.remove(styles.masterShow);
            }
        }
    }

    componentDidMount(): void {
        if (this.$node.current !== null) {
            if (this.props.show) {
                this.$node.current.classList.add(styles.masterShow);
            }

            this.$childs = Array.prototype.slice.call(this.$node.current.getElementsByClassName(styles.childPage));
            for (let i = 0; i < this.$childs.length; i++) {
                // eslint-disable-next-line max-depth
                if (this.$childs[i].parentElement?.classList.contains(styles.persistent)) {
                    this.$childs.splice(i, 1);
                    break;
                }
            }
            this.updateChilds(this.$node.current);
        }
    }

    render(): ReactElement {
        return (
            <div ref={this.$node} className={styles.masterPage}>
                <div className={styles.background}>
                    {this.props.background}
                </div>
                <div className={styles.persistent}>
                    <Child>
                        {this.props.persistent}
                    </Child>
                </div>
                {this.props.children}
            </div>
        );
    }
}

interface IChildPage {
    background?: ReactElement
}
class Child extends React.Component<IChildPage>{
    private $node: React.RefObject<HTMLDivElement>;

    constructor(props: IChildPage) {
        super(props);
        this.$node = React.createRef();
    }

    getMissing(): ReactElement[] {
        const elements = (Array.isArray(this.props.children)) ? [...this.props.children] : [this.props.children];
        const contentElementClasses = ['top', 'center', 'bottom'];

        // eslint-disable-next-line consistent-return
        const types: typeof contentElementClasses = (() => {
            const list: typeof contentElementClasses = [];
            for (const element of elements) {
                list.push(element.props.className);
            }

            return list;
        })();

        const missing: typeof contentElementClasses = [];
        for (const elementClass of contentElementClasses) {
            if (!types.includes(elementClass)) {
                missing.push(elementClass);
            }
        }

        if (missing.includes('top')) {
            elements.unshift(<div className='top' />);
        }

        if (missing.includes('center')) {
            elements.splice(1, 0, <div className='center' />);
        }

        if (missing.includes('bottom')) {
            elements.push(<div className='bottom' />);
        }

        return elements;
    }

    get childs(): ReactElement[] | ReactElement | undefined {
        const childs: ReactNode[] | ReactNode | undefined = this.props.children;

        if (childs === undefined) {
            return undefined;
        }

        if (Array.isArray(childs)) {
            if (childs.length >= 3) {
                return childs;
            }
        }

        return this.getMissing();
    }

    componentDidMount(): void {
        if (this.$node !== null && this.$node.current?.parentElement?.classList.contains(styles.persistent)) {
            this.$node.current.classList.add(styles.childShow);
        }
    }

    render(): ReactElement {
        return (
            <div ref={this.$node} className={styles.childPage}>
                <div className={styles.background}>
                    {this.props.background}
                </div>
                <div className={styles.content}>
                    {this.childs}
                </div>
            </div>
        );
    }
}

interface IWallpaper {
    src: string
    alt?: string
}
class Wallpaper extends React.Component<IWallpaper>{
    render(): ReactElement {
        return (
            <img className={styles.wallpaper} src={this.props.src} alt={this.props.alt} />
        );
    }
}

interface IGridWallpaper {
    src: string[]
}
class GridWallpaper extends React.Component<IGridWallpaper>{
    render(): React.ReactNode {
        const images = this.props.src.map((src: string) => <img src={src} alt='' />);

        return(
            <div className={styles.gridWallpaper}>
                <div>
                    {images}
                </div>
                <div>
                    {images}
                </div>
            </div>
        );
    }
}

class Title extends React.Component {
    render(): ReactElement {
        return (
            <p className={styles.title}>
                {this.props.children}
            </p>
        );
    }
}

class Subtitle extends React.Component {
    render(): ReactElement {
        return (
            <span className={styles.subtitle}>
                {this.props.children}
            </span>
        );
    }
}

class Supporter extends React.Component<{ src: string, alt?: string, notext?: true, href?: string }> {
    render(): ReactElement {
        return (
            <div className={styles.supporter}>
                {this.props.href
                    && <a href={this.props.href} target='_blank'></a>
                }
                <img src={this.props.src} alt={this.props.alt} />
                {!this.props.notext
                    && <span>Gefördert durch:</span>
                }
            </div>
        );
    }
}

class Text extends React.Component<{ direction?: 'right' | 'left' | 'center', style?: React.CSSProperties }> {
    private get containerClassName(): string {
        const directionClassName = (() => {
            switch (this.props.direction) {
                case 'left': return styles.textContainerLeft;
                case 'center': return styles.textContainerCenter;
                case 'right': return '';

                default: {
                    switch (Math.floor(Math.random() * 3)) {
                        case 1: return styles.textContainerCenter;
                        case 2: return styles.textContainerLeft;

                        default: return '';
                    }
                }
            }
        })();

        return `${styles.textContainer} ${directionClassName}`;
    }
    render(): ReactElement {
        return (
            <div className={this.containerClassName}>
                <span className={styles.textBox} style={this.props.style}>
                    {this.props.children}
                </span>
            </div>
        );
    }
}

interface IPlayButton {
    src: string
    title?: string | ReactNode
    isYoutube?: boolean
}
class PlayButton extends React.Component<IPlayButton> {
    render(): ReactElement {
        return (
            <div className={styles.play}>
                <div onClick={() => pushVideoPlayer(this.props)}>
                    <Icon className={styles.playIcon} icon='ion-ios-play'></Icon>
                </div>
                <span onClick={() => pushVideoPlayer(this.props)}>
                    Klicken Sie hier, um das Video zu sehen.
                </span>
            </div>
        );
    }
}

export { Master, Child, Wallpaper, GridWallpaper, Title, Subtitle, Supporter, Text, PlayButton };
