/* eslint-disable max-len */
import { ReactElement } from 'react';
import * as Pages from '../../../components/pages';
import { IProps } from '../all';
import wallpaper from '../../../assets/images/last_bg.png';

function Page(props: IProps): ReactElement {
    return (
        <Pages.Master show={props.show} selected={props.selected} persistent={
            <div className='top'>
                <Pages.Title>
                    Herzlichen Dank
                </Pages.Title>
                <Pages.Subtitle>
                    an unsere Kunden, Partner, Lieferanten und alle anderen Wegbegleiter
                </Pages.Subtitle>
            </div>
        } background={<Pages.Wallpaper src={wallpaper} />}>
            <Pages.Child>
                <div className='center'>
                    <Pages.PlayButton src='TYHCmcIzw3M' title='Happy Birthday Infokom!' isYoutube />
                </div>
                <div className='bottom'>
                    <Pages.Text>
                        Video: Happy Birthday!
                    </Pages.Text>
                </div>
            </Pages.Child>
        </Pages.Master>
    );
}

const pEnd = [Page, '2022'];

export default pEnd;
