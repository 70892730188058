/* eslint-disable max-len */
import { ReactElement } from 'react';
import * as Pages from '../../../components/pages';
import { IProps } from '../all';
import wallpaper from '../../../assets/images/fib_bg.jpg';
import supporter from '../../../assets/images/BMWi.svg';

function Page(props: IProps): ReactElement {
    return (
        <Pages.Master show={props.show} selected={props.selected} persistent={
            <div className='top'>
                <Pages.Title>
                    FIB
                </Pages.Title>
                <Pages.Subtitle>
                    Fahrzeugidentifikation bei Überlastfahrten auf Brücken FIB-Brückenmonitoring
                </Pages.Subtitle>
                <Pages.Supporter src={supporter} />
            </div>
        } background={<Pages.Wallpaper src={wallpaper} />}>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Automatische Erkennung von Fahrzeugüberfahrten.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Ermittlung des Fahrzeugsgewichts.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Bei Überschreitung des zulässigen Gesamtgewichts für die Brücke wurde ein Foto erstellt.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Sicherstellung der Einhaltung von Fahrzeuggewichten und die Erhaltung kritischer Brückenzustände für die weitere Nutzung mit eingeschränktem zulässigen Gesamtgewicht.
                    </Pages.Text>
                </div>
            </Pages.Child>
        </Pages.Master>
    );
}

const p2002 = [Page, '2002'];

export default p2002;
