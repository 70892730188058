/* eslint-disable max-len */
import { ReactElement } from 'react';
import * as Pages from '../../../components/pages';
import { IProps } from '../all';
import wallpaper from '../../../assets/images/automotiv_bg.jpg';
import supporter from '../../../assets/images/esf.png';

function Page(props: IProps): ReactElement {
    return (
        <Pages.Master show={props.show} selected={props.selected} persistent={
            <div className='top'>
                <Pages.Title>
                    Automotive
                </Pages.Title>
                <Pages.Subtitle>
                    Qualifizierungsinitiative der Automobilzulieferindustrie in Mecklenburg-Vorpommern
                </Pages.Subtitle>
                <Pages.Supporter src={supporter} />
            </div>
        } background={<Pages.Wallpaper src={wallpaper} />}>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Qualifizierte Schulungen für Ingenieure im Fachbereich der Automobilbranche.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Organisation und Durchführung von Präsenzschulungen mit nationalen und internationalen Autoren.
                    </Pages.Text>
                </div>
            </Pages.Child>
        </Pages.Master>
    );
}

const p2008 = [Page, '2008'];

export default p2008;
