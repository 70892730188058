import React from 'react';
import ReactDOM from 'react-dom';
import ons from 'onsenui';
import './index.scss';
import 'onsenui/css/onsenui.css';
import './onsen-css-components.min.css';
import './theme.css';
import Timeline from './views/timeline';
import StartupDialog from './views/startup';
import reportWebVitals from './reportWebVitals';

ons.platform.select('ios');
ReactDOM.render(
    <React.StrictMode>
        <StartupDialog/>
        <Timeline/>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
