import React, { ReactElement } from 'react';
import { AlertDialog, Button, Page } from 'react-onsenui';
import styles from './startup.module.scss';

function StartupDialog(): ReactElement {
    const [isShown, setIsShown] = React.useState(true);
    function okClick() {
        setIsShown(false);
    }

    return (
        <Page>
            <AlertDialog isOpen={isShown} isCancelable={false} className={styles.dialog}>
                <div className='alert-dialog-title' style={{ borderBottom: '1px solid var(--alert-dialog-separator-color)', paddingBottom: '8px' }}>Information</div>
                <div className='alert-dialog-content'>Klicken sie oben links auf das Infokom-Symbol um das Seitenmenü anzuzeigen und weitere Informationen zu finden.</div>
                <div className='alert-dialog-footer'>
                    <Button className='alert-dialog-button'><a href='https://infokom.de/datenschutzerklaerung/' target='_blank'>Datenschutz</a></Button>
                    <Button className='alert-dialog-button'><a href='https://infokom.de/impressum/' target='_blank'>Impressum</a></Button>
                    <Button className='alert-dialog-button' onClick={okClick}>OK</Button>
                </div>
            </AlertDialog>
        </Page>
    );
}

export default StartupDialog;
