/* eslint-disable max-len */
import { ReactElement } from 'react';
import * as Pages from '../../../components/pages';
import { IProps } from '../all';
import wallpaper from '../../../assets/images/fearless_bg.jpg';
import fearlessLogo from '../../../assets/images/fearless_logo.png';
import supporter from '../../../assets/images/bmvit.png';

function Page(props: IProps): ReactElement {
    return (
        <Pages.Master show={props.show} selected={props.selected} persistent={
            <div className='top'>
                <Pages.Title>
                    <img src={fearlessLogo} alt='' />
                </Pages.Title>
                <Pages.Subtitle>
                    <u>F</u>ear <u>E</u>limination <u>A</u>s <u>R</u>esolution for <u>L</u>oosing <u>E</u>lderly’s <u>S</u>ubstantial <u>S</u>orrows.
                </Pages.Subtitle>
                <Pages.Supporter src={supporter} />
            </div>
        } background={<Pages.Wallpaper src={wallpaper} />}>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Entwicklung eines Sensorsystems, das in der Lage ist verschiedene Risiken im häuslichen Umfeld zu detektieren und eigenständig zu reagieren.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Das dadurch entstehende Sicherheitsgefühl verringert die Ängste älterer Menschen und erhöht die Mobilität.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Dies wiederum ermöglicht ihnen die längere Teilnahme an einem selbstbestimmten Leben.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='center'>
                    <Pages.PlayButton src='V28VXa1_a1Q' title='fearless life comfort system - the project' isYoutube />
                </div>
                <div className='bottom'>
                    <Pages.Text>
                        Video: fearless life comfort system - the project
                    </Pages.Text>
                </div>
            </Pages.Child>
        </Pages.Master>
    );
}

const p2014 = [Page, '2014'];

export default p2014;
