class Swipe {
    $element: HTMLElement;
    private $xDown = 0;
    private $xDiff = 0;
    private $onLeft: (() => void) | undefined;
    private $onRight: (() => void) | undefined;

    constructor(element: HTMLElement) {
        element.ontouchstart = (e) => {
            this.$xDown = e.touches[0].clientX;
        };

        this.$element = element;
    }

    onLeft(callback: () => void): void {
        this.$onLeft = callback;
    }

    onRight(callback: () => void): void {
        this.$onRight = callback;
    }

    private handleTouchMove(e: TouchEvent) {
        if (!this.$xDown || e.touches[0].clientX < 80) {
            return;
        }
        const xUp = e.touches[0].clientX;

        this.$xDiff = this.$xDown - xUp;

        if (this.$xDiff > 0) {
            if (this.$onLeft !== undefined) {
                this.$onLeft();
            }
        } else {
            if (this.$onRight !== undefined) {
                this.$onRight();
            }
        }

        this.$xDown = 0;
    }

    run(): void {
        this.$element.ontouchmove = (e) => {
            this.handleTouchMove(e);
        };
    }
}

export default Swipe;
