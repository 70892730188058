/* eslint-disable class-methods-use-this */
import React, { ReactElement, ReactNode } from 'react';
import { Page, Splitter, SplitterContent, SplitterSide, Toolbar, Navigator } from 'react-onsenui';
import DropdownButton from '../../components/dropdownButton';
import InfokomLogo from '../../components/infokomLogo';
import AllPages from '../pages/all';
import Player from '../player';
import Sidebar from '../sidebar';
import styles from './timeline.module.scss';

interface IMainPageState {
    sidebarIsShown: boolean
    dropdownList: string[]
    dropdownTitle: string
    selectedPage: number
}
class MainPage extends React.Component<{ navigator: Navigator }, IMainPageState> {
    constructor(props: { navigator: Navigator }) {
        super(props);

        let page = 0;
        const urlParams = new URLSearchParams(window.location.search);
        const viewParam = urlParams.get('view');

        if (viewParam !== null) {
            const viewParamNum = parseInt(viewParam, 10);

            // eslint-disable-next-line max-depth
            if (!isNaN(viewParamNum)) {
                page = viewParamNum;
            }
        }

        this.state = {
            sidebarIsShown: false,
            dropdownList: [],
            dropdownTitle: '',
            selectedPage: page,
        };
    }

    public hideSidebar = () => {
        this.setState({
            sidebarIsShown: false,
        });
    }

    public showSidebar = () => {
        this.setState({
            sidebarIsShown: true,
        });
    }

    public pushPage = () => {
        void this.props.navigator.pushPage({ component: VideoPlayer });
    }

    getDropdownList = (value: string[]) => {
        this.setState({
            dropdownList: value,
        });
    }

    getDropdownTitle = (value: string) => {
        this.setState({
            dropdownTitle: value,
        });
    }

    setSelectedPage = (value: number) => {
        this.setState({
            selectedPage: value,
        });
    }

    render(): ReactElement {
        return (
            <Page>
                <Splitter>
                    <SplitterSide swipeTargetWidth={80} isOpen={this.state.sidebarIsShown} side='left' width={220}
                        onOpen={this.showSidebar} onClose={this.hideSidebar} collapse={true} swipeable={true} animation={'push' as 'default'}>
                        <Sidebar />
                    </SplitterSide>
                    <SplitterContent>
                        <Page className={styles.page}>
                            <div className={styles.pages}>
                                <AllPages
                                    sendList={this.getDropdownList}
                                    sendTitle={this.getDropdownTitle}
                                    selectedPage={this.state.selectedPage}
                                />
                            </div>
                            <Toolbar className={styles.toolbar}>
                                <div className={`left ${styles.toolbarLeft}`}>
                                    <InfokomLogo
                                        className={styles.logo}
                                        isStatic={true}
                                        onClick={this.showSidebar}
                                    />
                                </div>
                                <div className={`center ${styles.toolbarCenter}`}>

                                </div>
                                <div className={`right ${styles.toolbarRight}`}>
                                    <DropdownButton
                                        className={styles.dropdownButton}
                                        title={this.state.dropdownTitle}
                                        list={this.state.dropdownList}
                                        selectPage={this.setSelectedPage}
                                    />
                                </div>
                            </Toolbar>
                        </Page>
                    </SplitterContent>
                </Splitter>
            </Page>
        );
    }
}

interface IPlayerProps {
    src: string
    title?: string | ReactNode
    isYoutube?: boolean
}
let playerProps: IPlayerProps = {
    src: '',
};
class VideoPlayer extends React.Component<{ navigator: Navigator }> {
    pushPage() {
        void this.props.navigator.pushPage({ component: VideoPlayer });
    }

    popPage() {
        void this.props.navigator.popPage();
    }

    render() {
        return (
            <Player onBackClicked={() => this.popPage()} src={playerProps.src}
                title={playerProps.title} isYoutube={playerProps.isYoutube} />
        );
    }
}

let mainNavigator: Navigator | undefined = undefined;
class Timeline extends React.Component {
    renderPage(route: { props: { navigator: Navigator | undefined }, component: string }, navigator: Navigator | undefined): ReactElement {
        const props = route.props || {};
        props.navigator = navigator;
        mainNavigator = navigator;

        // eslint-disable-next-line consistent-return
        window.onbeforeunload = () => {
            if (navigator?.pages !== undefined && navigator.pages.length > 1) {
                navigator?.popPage();

                return 'Wollen Sie die Seite wirklich verlassen?\nKlicken Sie auf "Bleiben" um das Video zu schließen';
            }
        };

        return React.createElement(route.component, props);
    }

    render(): ReactElement {
        return (
            <Navigator
                initialRoute={{ component: MainPage }}
                renderPage={this.renderPage}
            />
        );
    }
}

export function pushVideoPlayer(props: IPlayerProps): void {
    if (mainNavigator !== undefined) {
        playerProps = props;
        void mainNavigator.pushPage({ component: VideoPlayer });
    }
}
export default Timeline;
