/* eslint-disable max-len */
import { ReactElement } from 'react';
import * as Pages from '../../../components/pages';
import { IProps } from '../all';
import wallpaper from '../../../assets/images/Technologie.jpg';

function Page(props: IProps): ReactElement {
    return (
        <Pages.Master show={props.show} selected={props.selected} persistent={
            <div className='top'>
                <Pages.Title>
                    Digitale Technologien
                </Pages.Title>
            </div>
        } background={<Pages.Wallpaper src={wallpaper} />}>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Viele innovative Projekte - national und international.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Telemedizinische Anwendungen für den sicheren Datenaustausch.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Entwicklung für verschiedene Endgeräte wie z.B. Smartphone und Tablet.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Digitalen Kerntechnologie des Unternehmens kam in vielen mit Preisen ausgezeichneten Projekten zum Einsatz.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='center'>
                    <Pages.PlayButton src='5zAFAuqD93M' title='Interview Petra Preik' isYoutube />
                </div>
                <div className='bottom'>
                    <Pages.Text>
                        Video: Interview Petra Preik
                    </Pages.Text>
                </div>
            </Pages.Child>
        </Pages.Master>
    );
}

const p2015 = [Page, '2015'];

export default p2015;
