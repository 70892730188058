/* eslint-disable max-len */
import { ReactElement } from 'react';
import * as Pages from '../../../components/pages';
import { IProps } from '../all';
import wallpaper from '../../../assets/images/corro_bg.jpg';
import supporter from '../../../assets/images/BMWi.svg';

function Page(props: IProps): ReactElement {
    return (
        <Pages.Master show={props.show} selected={props.selected} persistent={
            <div className='top'>
                <Pages.Title>
                    Corro
                </Pages.Title>
                <Pages.Subtitle>
                    Correlation Robotics Bildkorrelationsbasierte Navigation autonomer mobiler Serviceroboter im Einsatzumfeld
                </Pages.Subtitle>
                <Pages.Supporter src={supporter} />
            </div>
        } background={<Pages.Wallpaper src={wallpaper} />}>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Entwicklung eines Mähroboters mit Unterstützung durch bilderkennenden Sensoren.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Verwendung geeigneter Algorithmen zur Bildanalyse.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Versuchsreihen unter Laborbedingungen.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Auswertung und Veröffentlichung der Ergebnisse.
                    </Pages.Text>
                </div>
            </Pages.Child>
        </Pages.Master>
    );
}

const p2011 = [Page, '2011'];

export default p2011;
