/* eslint-disable max-len */
import React, { ReactElement } from 'react';
import * as Pages from '../../../components/pages';
import { IProps } from '../all';
import styles from './content.module.scss';
import wallpaper from '../../../assets/images/datenschutz_bg.jpg';
import img0 from '../../../assets/images/Schulung Datenschutz.jpg';
import img1 from '../../../assets/images/Schulung Meldepflicht.jpg';
import img2 from '../../../assets/images/Schulung Seetel IT-Sicherheit.jpg';
import img3 from '../../../assets/images/Schulung Passwortschutz.jpg';
import img4 from '../../../assets/images/Schulung Homeoffice.jpg';
import img5 from '../../../assets/images/Schulung Hackerangriffe.jpg';

class MediumImages extends React.Component<{ src: string[] }> {
    render(): ReactElement {
        const images = this.props.src.map((value) => <img src={value} alt='' />);

        return (
            <div className={styles.mediumImg}>
                {images}
            </div>
        );
    }
}

function Page(props: IProps): ReactElement {
    return (
        <Pages.Master show={props.show} selected={props.selected} persistent={
            <div className='top'>
                <Pages.Title>
                    Datenschutz
                </Pages.Title>
            </div>
        } background={<Pages.Wallpaper src={wallpaper} />}>
            <Pages.Child>
                <div className='center'>
                    <MediumImages src={[img0, img1]} />
                </div>
                <div className='bottom'>
                    <Pages.Text>
                        Datenschutzberatungen nach der Datenschutzgrundverordnung (DSGVO).
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='center'>
                    <MediumImages src={[img2, img3]} />
                </div>
                <div className='bottom'>
                    <Pages.Text>
                        Externer Datenschutzbeauftragter für Ihr Unternehmen.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='center'>
                    <MediumImages src={[img4, img5]} />
                </div>
                <div className='bottom'>
                    <Pages.Text>
                        Datenschutzschulungen vor Ort oder übers Internet.
                    </Pages.Text>
                </div>
            </Pages.Child>
        </Pages.Master>
    );
}

const p2018 = [Page, '2018'];

export default p2018;
