import React, { ReactElement } from 'react';
import IWindow from '../../window';
import pagesStyles from '../../components/pages/page.module.scss';
import Swipe from './swipe';
import p1991 from './content/1991';
import p1995 from './content/1995';
import p1999 from './content/1999';
import p2001 from './content/2001';
import p2002 from './content/2002';
import p2003 from './content/2003';
import p2005 from './content/2005';
import p2006 from './content/2006';
import p2007 from './content/2007';
import p2008 from './content/2008';
import p2009 from './content/2009';
import p2010 from './content/2010';
import p2011 from './content/2011';
import p2012 from './content/2012';
import p2013 from './content/2013';
import p2014 from './content/2014';
import p2015 from './content/2015';
import p2016 from './content/2016';
import p2017 from './content/2017';
import p2018 from './content/2018';
import p2019 from './content/2019';
import p2020 from './content/2020';
import p2021 from './content/2021';
import pEnd from './content/end';

const pageList: string[] = [];
let pageListIsDone = false;

enum EPage {
    CLASS,
    NAME
}
class Page extends React.Component<{
    page: (string | ((props: IProps) => ReactElement))[]
    show: boolean
    selected: number
}> {

    componentDidMount() {
        if (!pageListIsDone) {
            pageList.push(this.props.page[EPage.NAME] as string);
        }
    }

    render(): ReactElement {
        return React.createElement(this.props.page[EPage.CLASS], {
            show: this.props.show,
            selected: this.props.selected,
        });
    }
}

let currentPage = 0;
let currentSubpage = 0;
let lastSelected = 0;
interface IAllPages {
    sendList: (value: string[]) => void
    sendTitle: (value: string) => void
    selectedPage: number;
}
class AllPages extends React.Component<IAllPages, { selected: number, selectedSub: number, container: React.RefObject<HTMLDivElement> }> {
    constructor(props: IAllPages) {
        super(props);

        this.state = {
            selected: currentPage,
            selectedSub: currentSubpage,
            container: React.createRef(),
        };
    }

    addControls(): void {
        window.onkeyup = (e) => {
            switch (e.key) {
                case ' ': this.nextPage(1); break;
                case 's': this.nextPage(1); break;
                case 'd': this.nextPage(1); break;
                case 'w': this.nextPage(-1); break;
                case 'a': this.nextPage(-1); break;
                case 'ArrowUp': this.nextPage(-1); break;
                case 'ArrowLeft': this.nextPage(-1); break;
                case 'ArrowDown': this.nextPage(1); break;
                case 'ArrowRight': this.nextPage(1); break;

                default: break;
            }
        };
        window.onwheel = (e) => {
            if (e.deltaY > 0) {
                this.nextPage(1);
            } else {
                this.nextPage(-1);
            }
        };
    }

    selectMaster(index: number): void {
        currentPage = index;
        currentSubpage = 0;
        this.setState({
            selected: currentPage,
            selectedSub: 0,
        });
        this.props.sendTitle(pageList[currentPage]);
    }

    nextPage(value: 1 | -1): void {
        const nextMaster = (current: HTMLDivElement) => {
            if (currentPage + value >= 0 && currentPage + value < current.childElementCount) {
                currentPage += value;
                this.setState({
                    selected: currentPage,
                    selectedSub: 0,
                });
                if (this.state.container.current !== null) {
                    const childs = this.state.container.current.querySelectorAll(`.${pagesStyles.masterPage}.${pagesStyles.masterShow} > .${pagesStyles.childPage}`);
                    // eslint-disable-next-line max-depth
                    if (childs.length > 0) {
                        currentSubpage = (value > 0) ? 0 : childs.length - 1;
                    } else {
                        currentSubpage = 0;
                    }
                } else {
                    currentSubpage = 0;
                }
                this.setState({
                    selectedSub: currentSubpage,
                });
                this.props.sendTitle(pageList[currentPage]);
            }
        };

        const nextChild = (childs: NodeListOf<Element>, current: HTMLDivElement) => {
            if (currentSubpage + value >= 0 && currentSubpage + value < childs.length) {
                currentSubpage += value;
                this.setState({
                    selectedSub: currentSubpage,
                });
            } else {
                nextMaster(current);
            }
        };

        if ((window as unknown as IWindow).noScroll !== true && this.state.container.current !== null) {
            const childs = this.state.container.current.querySelectorAll(`.${pagesStyles.masterPage}.${pagesStyles.masterShow} > .${pagesStyles.childPage}`);
            nextChild(childs, this.state.container.current);
        }
    }

    componentDidMount(): void {
        this.addControls();
        if (this.state.container.current !== null) {
            const swipe = new Swipe(this.state.container.current);
            swipe.onLeft(() => this.nextPage(1));
            swipe.onRight(() => this.nextPage(-1));
            swipe.run();
        }

        pageListIsDone = true;
        this.props.sendList(pageList);
        this.props.sendTitle(pageList[currentPage]);
    }

    componentDidUpdate(): void {
        this.addControls();

        if (this.props.selectedPage !== lastSelected) {
            lastSelected = this.props.selectedPage;
            this.selectMaster(this.props.selectedPage);
        }
    }

    render(): ReactElement {

        // add new Pages here:
        const list = [
            p1991, p1995, p1999, p2001, p2002, p2003, p2005,
            p2006, p2007, p2008, p2009, p2010, p2011, p2012,
            p2013, p2014, p2015, p2016, p2017, p2018, p2019,
            p2020, p2021, pEnd,
        ];


        const elementList = list.map((page, i) => <Page page={page} selected={this.state.selectedSub} show={this.state.selected === i} />);

        return (
            <div ref={this.state.container} style={{ width: '100%', height: '100%' }}>
                {elementList}
            </div>
        );
    }
}

export interface IProps {
    show: boolean
    selected: number
}
export default AllPages;
