/* eslint-disable max-len */
import { ReactElement } from 'react';
import * as Pages from '../../../components/pages';
import { IProps } from '../all';
import openingImg from '../../../assets/images/infokom_bg.jpg';
import wallpaper from '../../../assets/images/opening.jpg';
import InfokomLogo from '../../../components/infokomLogo';

function Page(props: IProps): ReactElement {
    return (
        <Pages.Master show={props.show} selected={props.selected} persistent={
            <div className='top'>
                <Pages.Title>
                    30 Jahre Infokom
                </Pages.Title>
                <Pages.Subtitle>
                    Herzlich Willkommen auf der interaktiven Präsentation
                </Pages.Subtitle>
            </div>
        } background={<Pages.Wallpaper src={wallpaper} />}>
            <Pages.Child background={<Pages.Wallpaper src={openingImg} />}>
                <div className='center'>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                    }}>
                        <InfokomLogo animate={true} showBackground={false} />
                    </div>
                </div>
                <div className='bottom'>
                    <Pages.Text direction='center'>
                        Nutzen sie zum Navigieren Pfeiltasten, Mausrad oder Touch
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child background={<Pages.Wallpaper src={openingImg} />}>
                <div className='bottom'>
                    <Pages.Text style={{ maxWidth: 'unset', fontSize: '2rem' }}>
                        Ich lade Sie ein, diese Zeit mit der interaktiven Multimedia Präsentation nachzuvollziehen und anhand von Dokumenten, Bildern, Interviews, Erlebnissen, Projekten diese Zeit nachzuzeichnen. Sie war und ist geprägt vom Willen und Können unserer Mitarbeiter, sich immer wieder den Herausforderungen des Marktes, den Anforderungen unserer Kunden durch Innovation, Leistungsstärke und Verlässlichkeit zu stellen. In diesem Sinne fühlen wir uns auch künftig unseren Kunden und Partnern verpflichtet. Ich wünsche Ihnen viel Freunde beim Besuch dieser Präsentation.<br /><br />
                        Ihr Rolf-Dietrich Berndt<br />
                        Geschäftsführer<br />
                        Infokom GmbH
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text style={{ fontSize: '2rem' }} direction='left'>
                        Es war der 30.11.1991, als sich die Türen des neuen Computerland Centers in der Ihlenfelder Strasse 118 in Neubrandenburg öffneten. Der Andrang war enorm, die Stimmung super.<br />
                        Viele Besucher kamen, ein Preisausschreiben erkor einen Gewinner des nagelneuen PC. Das war die Geburtsstunde von Infokom. Heute nach 30 Jahren kann Infokom mit Stolz auf eine interessante, herausfordernde Zeit zurückblicken.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='center'>
                    <Pages.PlayButton src='usckWKG7Njw' title='Interview Dieter Fuhs' isYoutube />
                </div>
                <div className='bottom'>
                    <Pages.Text>
                        Video: Interview Dieter Fuhs.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='center'>
                    <Pages.PlayButton src='ly0lrtzdFYk' title='Interview Karl Ulrich Schönemeier' isYoutube />
                </div>
                <div className='bottom'>
                    <Pages.Text>
                        Video: Interview Karl Ulrich Schönemeier.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='center'>
                    <Pages.PlayButton src='vTYoVAkEklA' title='Interview Klaus Hopp' isYoutube />
                </div>
                <div className='bottom'>
                    <Pages.Text>
                        Video: Interview Klaus Hopp.
                    </Pages.Text>
                </div>
            </Pages.Child>
        </Pages.Master>
    );
}

const p1991 = [Page, '1991'];

export default p1991;
