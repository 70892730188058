/* eslint-disable max-len */
import { ReactElement } from 'react';
import * as Pages from '../../../components/pages';
import { IProps } from '../all';
import wallpaper from '../../../assets/images/eHealthMv_bg.jpg';
import awardBg from '../../../assets/images/eHealth_release.jpg';
import supporter from '../../../assets/images/EFRE.png';

function Page(props: IProps): ReactElement {
    return (
        <Pages.Master show={props.show} selected={props.selected} persistent={
            <div className='top'>
                <Pages.Title>
                    eHealth-MV
                </Pages.Title>
                <Pages.Supporter src={supporter} />
            </div>
        } background={<Pages.Wallpaper src={wallpaper} />}>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Präventivmedizin in Mecklenburg Vorpommern.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Intelligente Diagnostik spezieller physischer und psychomentaler Beanspruchungen.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Stress- und Fitness-Daten werden auf Basis von physiologischen Parametern ermittelt, ohne dass ein Arztbesuch notwendig ist.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Die Ergebnisdaten stehen für die Teilnehmer in Anwenderportalen zum Abruf bereit.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='center'>
                    <Pages.PlayButton src='DtCL_4Uz6V0' title='eHealth-MV' isYoutube />
                </div>
                <div className='bottom'>
                    <Pages.Text>
                        Video: eHealth-MV
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child background={<Pages.Wallpaper src={awardBg} />}>
                <div className='bottom'>
                    <Pages.Text>
                        Das Projekt eHealth-MV erhielt den AOK Award of Prevention, CeBit 2012.
                    </Pages.Text>
                </div>
            </Pages.Child>
        </Pages.Master>
    );
}

const p2012 = [Page, '2012'];

export default p2012;
