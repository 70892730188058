import React, { ReactElement, ReactNode } from 'react';
import { BackButton, Page, Toolbar } from 'react-onsenui';
import styles from './player.module.scss';
import * as cookie from '../../cookie';
import ReactDOM from 'react-dom';
import ons from 'onsenui';

interface IPlayer {
    onBackClicked: () => void
    src: string
    title?: string | ReactNode
    isYoutube?: boolean
}
class Player extends React.Component<IPlayer> {
    private $container: React.RefObject<HTMLDivElement>;

    constructor(props: IPlayer) {
        super(props);

        this.$container = React.createRef();
    }

    private requestYoutube = () => {
        void cookie.openDialog().then((allowed) => {
            if (allowed) {
                this.renderPlayer();
            } else {
                this.props.onBackClicked();
            }
        });
    }

    componentDidMount(): void {
        window.onkeyup = null;
        window.onwheel = null;

        if (this.props.isYoutube) {
            if (cookie.getState()) {
                this.renderPlayer();
            } else {
                this.requestYoutube();
            }
        }else{
            this.renderPlayer();
        }
    }

    private renderPlayer = () => {
        const error = () => {
            void ons.notification.alert('Dieses Video scheint nicht mehr Verfügbar zu sein', {
                title: 'Fehler',
                cancelable: true,
                callback: this.props.onBackClicked,
            });
        };

        if(this.$container !== undefined){
            if (this.props.isYoutube) {
                ReactDOM.render(
                    <iframe className={styles.iframe} src={`https://www.youtube.com/embed/${this.props.src}`} title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen></iframe>,
                    this.$container.current,
                );
            }else{
                void fetch(`/videos/${this.props.src}`).then((request) => {
                    if(request.ok){
                        const videoRef: React.RefObject<HTMLVideoElement> = React.createRef();
                        ReactDOM.render(
                            <video ref={videoRef} className={styles.video} controls></video>,
                            this.$container.current,
                            () => {
                                if(videoRef.current !== null){
                                    videoRef.current.onerror = () => {
                                        videoRef.current?.classList.remove(styles.show);
                                        error();
                                    };
                                    videoRef.current.oncanplay = () => videoRef.current?.classList.add(styles.show);
                                    videoRef.current.src = request.url;        
                                    videoRef.current.disablePictureInPicture = true;
                                    videoRef.current.preload = 'auto';                         
                                }
                            },
                        );
                    }else{
                        error();
                    }
                });
            }
        }
    }

    render(): ReactElement {
        return (
            <Page className={styles.page} renderToolbar={() =>
                <Toolbar>
                    <div className='left'>
                        <BackButton style={{color: '#fff'}} onClick={this.props.onBackClicked}>Zurück</BackButton>
                    </div>
                    <div className='center'>{this.props.title}</div>
                </Toolbar>
            }>
                <div ref={this.$container} className={styles.container} />
            </Page>
        );
    }
}

export default Player;
