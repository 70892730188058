import React from 'react';
import styles from './dropdownButton.module.scss';
import IWindow from '../../window';

interface IDropdownButton {
    className?: string
    id?: string
    list: string[]
    title: string
    selectPage: (value: number) => void
    /*onSelect: () => void
    index: number*/
}
class DropdownButton extends React.Component<IDropdownButton> {
    private $className: string = styles.container
    private $node: React.RefObject<HTMLDivElement>;

    constructor(props: IDropdownButton) {
        super(props);

        if (props.className !== undefined) {
            this.$className += ` ${props.className}`;
        }

        this.$node = React.createRef();
    }

    componentDidMount(): void {
        if(this.$node.current !== null){
            this.$node.current.onmouseover = () => {
                (window as unknown as IWindow).noScroll = true;
            };
            this.$node.current.onmouseleave = () => {
                (window as unknown as IWindow).noScroll = false;
            };
        }
    }

    render(): React.ReactNode {
        const listItems = this.props.list.map((name, i) => <li onClick={() => this.props.selectPage(i)}>{name}</li>);

        return (
            <div ref={this.$node} id={this.props.id} className={this.$className}>
                <span>{this.props.title}</span>
                <div >
                    <ul>{listItems}</ul>
                </div>
            </div>
        );
    }
}

export default DropdownButton;
