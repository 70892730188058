/* eslint-disable max-len */
import { ReactElement } from 'react';
import * as Pages from '../../../components/pages';
import { IProps } from '../all';
import wallpaper from '../../../assets/images/mdiab_afrika_bg.jpg';
import supporter from '../../../assets/images/CBCA.jpg';

function Page(props: IProps): ReactElement {
    return (
        <Pages.Master show={props.show} selected={props.selected} persistent={
            <div className='top'>
                <Pages.Title>
                    MobilDiab-Afrika
                </Pages.Title>
                <Pages.Supporter src={supporter} />
            </div>
        } background={<Pages.Wallpaper src={wallpaper} />}>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Erprobung des Diabetes Management Systems in Afrika.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Große räumliche Distanz zwischen Diabetespatienten und Fachärzten.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Zentrale gesicherte Datenablage für einen besseren medizinischen Überblick zum Patienten.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Das Feedback des Arztes vermittelt Sicherheit in der Behandlung und steigert die Motivation des Patienten.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='center'>
                    <Pages.PlayButton src='-vneev4Ahrc' title='Interview Claude Takenga' isYoutube />
                </div>
                <div className='bottom'>
                    <Pages.Text>
                        Video: Interview Dr. Claude Takenga
                    </Pages.Text>
                </div>
            </Pages.Child>
        </Pages.Master>
    );
}

const p2013 = [Page, '2013'];

export default p2013;
