import React, { ReactElement } from 'react';
import { InfokomLogo as Logo } from './logo';
import styles from './infokomLogo.module.scss';
import icon from '../../assets/images/infokom_icon.jpg';

interface IInfokomLogo {
    isStatic?: boolean
    className?: string
    id?: string
    animate?: boolean
    showBackground?: boolean
    onClick?: () => unknown
}

class InfokomLogo extends React.Component<IInfokomLogo> {
    private $node: React.RefObject<HTMLDivElement>;
    private $animate = false;
    private $showBackground = true;
    private $className = '';
    private $id: string | undefined = undefined;

    constructor(props: IInfokomLogo) {
        super(props);
        this.$node = React.createRef();

        if (props.className !== undefined) {
            this.$className = props.className;
        }
        if (props.id !== undefined) {
            this.$id = props.id;
        }

        if (props.animate !== undefined) {
            this.$animate = props.animate;
        }
        if (props.showBackground !== undefined) {
            this.$showBackground = props.showBackground;
        }

    }

    componentDidMount(): void {
        if (this.$node !== null) {
            new Logo({
                parentContainer: this.$node.current,
                animate: this.$animate,
                showBackground: this.$showBackground,
            });
        }
    }

    render(): ReactElement {
        if(this.props.isStatic){
            return <div className={`${styles.staticLogo} ${this.$className}`} id={this.$id} onClick={(this.props as IInfokomLogo).onClick}><img src={icon} alt='Infokom Logo' /></div>;
        }

        return <div ref={this.$node} className={`${styles.logo} ${this.$className}`} id={this.$id} onClick={(this.props as IInfokomLogo).onClick}/>;
    }
}

export default InfokomLogo;
