/* eslint-disable max-len */
import { ReactElement } from 'react';
import * as Pages from '../../../components/pages';
import { IProps } from '../all';
import wallpaper from '../../../assets/images/Teledermatologie_bg.jpg';
import logo from '../../../assets/images/Teledermatologie_logo.png';

function Page(props: IProps): ReactElement {
    return (
        <Pages.Master show={props.show} selected={props.selected} persistent={
            <div className='top'>
                <Pages.Title>
                    <img src={logo} alt='' />
                </Pages.Title>
            </div>
        } background={<Pages.Wallpaper src={wallpaper} />}>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Telemedizinische Konsile im Bereich der Dermatologie.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Neue Versorgungsstrukturen zum Ausgleich ländlicher Versorgungsdefizite.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Sektorübergreifende Behandlung von Patienten.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Teilnahme von über 100 Hausarztpraxen, mehr als 10 Krankenhäusern und weiteren Dermatologen.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='center'>
                    <Pages.PlayButton src='7RKjcW-qzL0' title='Teledermatologie: Die DermaApp' isYoutube />
                </div>
                <div className='bottom'>
                    <Pages.Text>
                        Video: Teledermatologie: Die DermaApp
                    </Pages.Text>
                </div>
            </Pages.Child>
        </Pages.Master>
    );
}

const p2020 = [Page, '2020'];

export default p2020;
