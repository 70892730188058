/* eslint-disable max-len */
import { ReactElement } from 'react';
import * as Pages from '../../../components/pages';
import { IProps } from '../all';
import wallpaper from '../../../assets/images/Morphosoric_bg.jpg';

function Page(props: IProps): ReactElement {
    return (
        <Pages.Master show={props.show} selected={props.selected} persistent={
            <div className='top'>
                <Pages.Title>
                    MORPHOSORIC<sup>®</sup>
                </Pages.Title>
            </div>
        } background={<Pages.Wallpaper src={wallpaper} />}>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Entwicklung und Produktion von biometrischen Sensoren und Sensorsystemen.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Typische und einzigartige Merkmale eines Fingerabdrucks als biometrische Signatur.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Herstellung von Fingerabdruckscannern und Einrichtung beim Kunden.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Aus- und Weiterbildung von Fachkräften.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='center'>
                    <Pages.PlayButton src='zO_GE-Nbg8Q' title={<>Das Projekt Morphosoric<sup>®</sup></>} isYoutube />
                </div>
                <div className='bottom'>
                    <Pages.Text>
                        Video: Das Projekt Morphosoric<sup>®</sup>
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='center'>
                    <Pages.PlayButton src='E-vHQcH_I7c' title={<>NDR Morphosoric<sup>®</sup> Fingerprint</>} isYoutube />
                </div>
                <div className='bottom'>
                    <Pages.Text>
                        Video: NDR Morphosoric<sup>®</sup> Fingerprint
                    </Pages.Text>
                </div>
            </Pages.Child>
        </Pages.Master>
    );
}

const p1999 = [Page, '1999'];

export default p1999;
