/* eslint-disable max-len */
import { ReactElement } from 'react';
import * as Pages from '../../../components/pages';
import { IProps } from '../all';
import wallpaper from '../../../assets/images/werkstatt_bg.jpg';
import nettoLogo from '../../../assets/images/netto-logo.svg';

function Page(props: IProps): ReactElement {
    return (
        <Pages.Master show={props.show} selected={props.selected} persistent={
            <div className='top'>
                <Pages.Title>
                    Servicebereich - <img src={nettoLogo} /><sup>®</sup>
                </Pages.Title>
            </div>
        } background={<Pages.Wallpaper src={wallpaper} />}>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Wartungs- und Repaturarbeiten von IT-Geräten, z.B. PC, Laptop, Drucker uvm.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Hunderte Kundeneinsätze pro Jahr.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Einzugsgebiete: Mecklenburg Vorpommern, Brandenburg, Berlin, Sachsen, Sachsen-Anhalt, Thüringen, Niedersachsen, Schleswig Holstein und Hamburg.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Austausch von Hardware, Installation und Einrichtung von Technik.
                    </Pages.Text>
                </div>
            </Pages.Child>
        </Pages.Master>
    );
}

const p2010 = [Page, '2010'];

export default p2010;
