/* eslint-disable max-len */
import { ReactElement } from 'react';
import * as Pages from '../../../components/pages';
import { IProps } from '../all';
import wallpaper from '../../../assets/images/MultiDiab_bg.jpg';

function Page(props: IProps): ReactElement {
    return (
        <Pages.Master show={props.show} selected={props.selected} persistent={
            <div className='top'>
                <Pages.Title>
                    Multi DIAB<sup>®</sup>
                </Pages.Title>
                <Pages.Subtitle>
                    Blutlose Blutzuckermessung
                </Pages.Subtitle>
            </div>
        } background={<Pages.Wallpaper src={wallpaper} />}>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Nicht invasive Blutzuckerermittlung mit Sensorik.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Entwicklung eines Verfahrens zur Auswertung der gemessenen Daten.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Aufbau der elektronischen Zunge und Erstellung eines Prototypen.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        Erprobung des Messsystems an verschiedenen Probanden.
                    </Pages.Text>
                </div>
            </Pages.Child>
        </Pages.Master>
    );
}

const p2007 = [Page, '2007'];

export default p2007;
