/* eslint-disable max-len */
import { ReactElement } from 'react';
import * as Pages from '../../../components/pages';
import { IProps } from '../all';
import styles from './content.module.scss';
import wallpaper from '../../../assets/images/inReakt_bg.jpg';
import inReaktLogo from '../../../assets/images/inReakt_logo.png';
import awardBg from '../../../assets/images/inReakt_award.jpg';
import award from '../../../assets/images/inReakt_award.png';
import supporter from '../../../assets/images/bmbf.png';

function Page(props: IProps): ReactElement {
    return (
        <Pages.Master show={props.show} selected={props.selected} persistent={
            <div className='top'>
                <Pages.Title>
                    <img src={inReaktLogo} alt='' />
                </Pages.Title>
                <Pages.Subtitle>
                    <u>In</u>tegrierte Hilfe-<u>REAKT</u>ionsketten zur Erhöhung der Sicherheit des ÖPNV
                </Pages.Subtitle>
                <Pages.Supporter src={supporter} />
            </div>
        } background={<Pages.Wallpaper src={wallpaper} />}>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        1. Erkennen:<br />Video- und Geräuschdetektion um sicherheitskritische Situationen und Notfälle im ÖPNV automatisiert zu erkennen.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        2. Melden:<br />Ereignis-Managementsystem in der Leitstelle des Verkehrsunternehmens mit Handlungsempfehlungen.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        3. Verständigen:<br />Für ein rascheres Intervenieren von Hilfskräften. (Polizei, Rettungsdienst, privater Sicherheitsdienst)
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='bottom'>
                    <Pages.Text>
                        4. Intervenieren:<br />Deeskalations- und Beschwichtigungsstrategien, die ferngesteuert sicherheitskritische Situationen beeinflussen.
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child>
                <div className='center'>
                    <Pages.PlayButton src='-YZlOsV__fU' title='InREAKT HR' isYoutube />
                </div>
                <div className='bottom'>
                    <Pages.Text>
                        Video: InREAKT HR
                    </Pages.Text>
                </div>
            </Pages.Child>
            <Pages.Child background={<Pages.Wallpaper src={awardBg} />}>
                <div className='center'>
                    <div className={styles.award}>
                        <img src={award} alt='' />
                    </div>
                </div>
                <div className='bottom'>
                    <Pages.Text>
                        InREAKT mit dem Deutschen Mobilitätspreis 2017 ausgezeichnet
                    </Pages.Text>
                </div>
            </Pages.Child>
        </Pages.Master>
    );
}

const p2016 = [Page, '2016'];

export default p2016;
