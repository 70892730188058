import ons from 'onsenui';
let alloweCookies = false;

function getState(): boolean {
    return alloweCookies;
}

function setState(state: boolean): void {
    alloweCookies = state;

    if(state){
        for (const resolve of whenAllowedPromises) {
            resolve();
        }
    }
}

const whenAllowedPromises: (() => void)[] = [];
async function whenAllowed(): Promise<void> {
    const promise = new Promise((resolve) => whenAllowedPromises.push(resolve as () => void));

    return (promise as Promise<void>);
}

async function openDialog(): Promise<boolean> {
    const promise = new Promise((resolve) => {
        if (!alloweCookies) {
            void ons.notification.confirm('YouTube nutzt Cookies, wollen sie diese zulassen?', {
                title: 'YouTube Video',
                buttonLabels: ['Nein', 'Ja'],
                callback: (choice: number) => {
                    if (choice === 1) {
                        setState(true);
                        resolve(true);
                    } else {
                        setState(false);
                        resolve(false);
                    }
                },
            });
        }
    });

    return (promise as Promise<boolean>);
}

export { getState, setState, whenAllowed, openDialog };
