import { ReactElement } from 'react';
import { Page } from 'react-onsenui';
import InfokomLogo from '../../components/infokomLogo';
import styles from './sidebar.module.scss';

function Sidebar(): ReactElement {
    return (
        <Page>
            <div className={styles.content}>
                <div className={styles.logoContainer}>
                    <a href='https://infokom.de' target='_blank'></a>
                    <InfokomLogo animate={true} className={styles.logo} />
                    <div className={styles.logoText}>
                        <p>Infokom</p>
                        <span>GmbH</span>
                    </div>
                </div>
                <div className={styles.body}>
                    <div />
                    <span>
                        <a href='https://infokom.de/datenschutzerklaerung/' target='_blank'>Datenschutz</a>
                        <a href='https://infokom.de/impressum/' target='_blank'>Impressum</a>
                    </span>
                </div>
            </div>
        </Page>
    );
}

export default Sidebar;
